.alert-maintenance {
  margin: 0 0 20px 0;

  > p {
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import './colors.scss';

.nav-tabs {
  flex-direction: row;
  border: none;
  background-color: $background-gray;

  .nav-item {
    flex: 1;
    text-align: center;
    height: 3rem;
    background-color: $background-gray;
    border: none;

    &:first-child {
      border-radius: 10px 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 10px 0 0 !important;
    }

    .nav-link {
      background-color: #e0dcdc;
      border: none;

      height: inherit;
      color: black;

      &.active {
        border: none;
        background-color: $white;
        border-radius: 10px 10px 0 0;
      }

      .nav-text {
        /* vertically center text */
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

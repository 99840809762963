@import '@teslagov/clarakm-js-react/src/styles/main.scss';
@import '_body';
@import '_card';
@import '_form';
@import '_icons';
@import '_input';
@import '_nav-tabs';
@import '_word-break';
@import '_modals';

#root {
  overflow: auto;
}

.redux-toastr {
  .rrt-error {
    a:link,
    a:hover,
    a:active,
    a:visited {
      color: #fff;
    }
  }
}

.card-block {
  a {
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.btn-sign-in {
  width: 150px;
  text-align: center;
}

.providers {
  .login-splitter:after {
    position: relative;
    content: 'or sign in with';
    top: -13px;
    left: 0;
    padding: 0 8px;
    background: $white;
    color: $gray-500;
  }
}

.login-footer {
  font-weight: 800;
}

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.card {
  box-shadow: 5px 5px 5px rgba(81, 76, 81, 0.2);
  border-radius: 10px;
  width: 500px;

  &.card-wide {
    width: 100%;
  }
}

@include media-breakpoint-only(xs) {
  .card {
    width: 95%;
  }
}

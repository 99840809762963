.disclaimer {
  width: 90%;
  max-width: 1000px;

  .content {
    > p {
      margin: 5px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
